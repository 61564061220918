import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import HeaderLandingDocSignature from "../../../components/header/landing/HeaderLandingDocSignature";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { getAllContent } from "../../FirebaseClient";
// import HeroBannerThree from "../../../components/hero-banner/HeroBannerThree";
// import GoogleSheetEmbed from "../../../views/GoogleSheetEmbed";

const Content = () => {
  const [allContent, setAllContent] = useState('');

  useEffect(() => {
    const fetchAllContent = async () => { setAllContent(await getAllContent()); }
    fetchAllContent()
  }, []);

  return (
    <div className="main-page-wrapper">
      <Helmet>
        <title>Content</title>
      </Helmet>
      {/* End Page SEO Content */}
      <HeaderLandingDocSignature />
      <div className="fancy-hero-six">
        <div className="container">
          <h1 className="heading">Contents</h1>
          <p className="sub-heading">
            My writings, videos about AI-related topics and systems connected to QUT. Not guaranteed to be useful to all readers but hopefully still interesting, at least for me :)
          </p>
          <ul className="d-flex justify-content-center">
            <li>
              <a href="https://medium.com/@hughiephan" target="_blank" rel="noreferrer">
                <i className="fa fa-medium"> </i>
              </a>
            </li>
            <li style={{ marginLeft: "10px" }}>
              <a href="https://www.youtube.com/@hughiephan" target="_blank" rel="noreferrer">
                <i className="fa fa-youtube-play"> </i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <TableContainer style={{ position: "relative", backgroundColor: "transparent" }} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Series</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Details</TableCell>
                <TableCell>Writing</TableCell>
                <TableCell>Video</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allContent && allContent.map((content) => (
                <TableRow key={content.title} sx={{"background-color": content.todo && content.todo.includes("all") ? "#FFF3DE" : "inherit",}}>
                  <TableCell> {content.series} </TableCell>
                  <TableCell> {content.title} </TableCell>
                  <TableCell> {content.details} </TableCell> 
                  <TableCell> {content.writing} </TableCell>
                  <TableCell> {content.video} </TableCell>
                  <TableCell> {content.date} </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* <GoogleSheetEmbed></GoogleSheetEmbed> */}
    </div>
  );
};

export default Content;
